@import url("https://cdn.rawgit.com/moonspam/NanumSquare/master/nanumsquare.css");

@font-face {
  font-family: "nanumsquare";
  src: url("https://cdn.jsdelivr.net/gh/Project-Noonnu/noonfonts_2107@1.1/Pretendard-Regular.woff")
    format("woff");
  font-style: normal;
}

@mixin flexCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}
@mixin tablet {
  @media (max-width: 1250px) {
    @content;
  }
}

@mixin mobile {
  @media (max-width: 700px) {
    @content;
  }
}

$font-Pretendard: "Pretendard-Regular";
$font-NanumSquare: "NanumSquare";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $font-NanumSquare;
}
:root {
  --vh: 100%;
}
ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

// Nav
.nav {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  @include flexCenter();
  padding: 20px 0;
  @include tablet {
    display: none;
  }
  .nav-inner {
    width: 100%;
    max-width: 1140px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .logo-box {
      width: 182px;
      img {
        width: 100%;
        object-fit: cover;
        cursor: pointer;
      }
    }
    .gnb {
      display: flex;
      align-items: center;
      gap: 50px;
      list-style: none;
      li {
        display: flex;
        gap: 20px;
        span {
          font-size: 18px;
          color: white;
          font-family: $font-NanumSquare;
          cursor: pointer;
        }
        img {
          width: 25px;
          object-fit: cover;
          cursor: pointer;
        }
      }
      .nav-icon {
        margin-left: 80px;
        .dropdown {
          button {
            display: flex;
            align-items: center;
            img {
              width: 25px;
              object-fit: cover;
            }
            &::after {
              border-top: 0.5em solid;
              border-right: 0.5em solid transparent;
              border-bottom: 0;
              border-left: 0.5em solid transparent;
            }
          }
        }
        button {
          position: relative;
          background-color: transparent;
          border: none;
          outline: none;
          svg {
            width: 34px;
            height: 34px;
            fill: none;
            stroke: white;
            stroke-width: 3px;
            stroke-linecap: round;
            stroke-linejoin: round;
          }
        }
      }
    }
  }
}
.nav.active {
  border-bottom: 1px solid #f9fafb;
  background-color: white;
  .nav-inner {
    .gnb {
      li {
        span {
          color: #333;
        }
      }
      .nav-icon {
        .dropdown {
          button {
            &::after {
              border-top: 0.5em solid black;
              border-right: 0.5em solid transparent;
              border-bottom: 0;
              border-left: 0.5em solid transparent;
            }
          }
        }
      }
    }
  }
}
// Sub-nab
.subnav {
  .nav-inner {
    .nav-icon {
      display: flex;
      gap: 20px;
      .dropdown {
        button {
          display: flex;
          align-items: center;
          img {
            width: 25px;
            object-fit: cover;
          }
          &::after {
            border-top: 0.5em solid black;
            border-right: 0.5em solid transparent;
            border-bottom: 0;
            border-left: 0.5em solid transparent;
          }
        }
      }
      button {
        position: relative;
        background-color: transparent;
        border: none;
        outline: none;
        svg {
          width: 34px;
          height: 34px;
          fill: none;
          stroke: white;
          stroke-width: 3px;
          stroke-linecap: round;
          stroke-linejoin: round;
        }
      }
    }
  }
}
// Mobile-Nav
.m-nav {
  display: none;
  width: 100%;
  z-index: 999;
  padding: 15px 0;
  @include tablet {
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    @include mobile {
      padding: 10px 0;
    }
    .m-nav-inner {
      width: 95%;
      margin: 0 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      .logo-box {
        width: 130px;
        margin-left: 10px;
        img {
          width: 100%;
          object-fit: cover;
          cursor: pointer;
        }
        @include mobile {
          width: 90px;
        }
      }
      .dropdown {
        position: absolute;
        right: 100px;
        @include mobile {
          right: 70px;
        }
        button {
          display: flex;
          align-items: center;
          img {
            width: 25px;
            object-fit: cover;
            @include mobile {
              width: 20px;
            }
          }
          &::after {
            border-top: 0.5em solid;
            border-right: 0.5em solid transparent;
            border-bottom: 0;
            border-left: 0.5em solid transparent;
            @include mobile {
              border-top: 0.4em solid;
              border-right: 0.4em solid transparent;
              border-bottom: 0;
              border-left: 0.4em solid transparent;
            }
          }
        }
      }
      button {
        position: relative;
        background-color: transparent;
        border: none;
        outline: none;
        svg {
          width: 34px;
          height: 34px;
          fill: none;
          stroke: white;
          stroke-width: 3px;
          stroke-linecap: round;
          stroke-linejoin: round;
          @include mobile {
            transform: scale(0.8);
          }
        }
      }
    }
  }
}
.m-nav.active {
  background-color: white;
  border-bottom: 1px solid #f9fafb;

  .m-nav-inner {
    .dropdown {
      button {
        &::after {
          border-top: 0.5em solid black;
          border-right: 0.5em solid transparent;
          border-bottom: 0;
          border-left: 0.5em solid transparent;
          @include mobile() {
            border-top: 0.4em solid black;
            border-right: 0.4em solid transparent;
            border-bottom: 0;
            border-left: 0.4em solid transparent;
          }
        }
      }
    }
    button {
      svg {
        stroke: black;
        stroke-width: 3px;
      }
    }
  }
}
.offcanvas.offcanvas-end {
  width: 100%;
  background-color: rgba(255, 255, 255, 0.7);
  backdrop-filter: blur(10px);
  border: none;
  .offcanvas-header {
    img {
      width: 130px;
      object-fit: cover;
      @include mobile {
        width: 100px;
      }
    }
    button {
      transform: scale(1.4);
      @include mobile {
        transform: scale(1.1);
      }
    }
  }
  .offcanvas-body {
    ul {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 20px;
      li {
        font-size: 30px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        color: black;
        cursor: pointer;
        &:hover {
          color: #333;
        }
        @include mobile {
          font-size: 22px;
        }
      }
    }
  }
}
.mobile-first-banner {
  display: none;
  @include mobile() {
    display: block;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: url(../public/assets/image/mobile-banner/m-banner01.jpg) center
      top no-repeat;
    background-size: cover;
    position: relative;
    .content {
      @include flexCenter();
      padding-top: 110px;
      h2 {
        font-size: 28px;
        line-height: 1.3;
        text-align: center;
        color: black;
        letter-spacing: 0px;
        font-family: $font-NanumSquare;
        font-weight: 900;
        pointer-events: none;
      }
    }
    .app-btn {
      margin-top: 15px;
      display: flex;
      gap: 30px;
      @include tablet {
        gap: 20px;
      }
      @include mobile() {
        justify-content: center;
      }
      button {
        font-size: 23px;
        font-family: $font-Pretendard;
        font-weight: 700;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 4px 13px;
        background-color: rgba(0, 0, 0, 0.6);
        border: none;
        outline: none;
        border-radius: 7px;
        cursor: pointer;
        @include tablet {
          font-size: 21px;
          padding: 6px 10px;
        }
        @include mobile {
          font-size: 14px;
          font-weight: 400;
          padding: 6px 10px;
          border-radius: 10px;
          background-color: rgba(0, 0, 0, 0.6);
        }
        img {
          width: 40px;
          @include tablet {
            width: 30px;
          }
          @include mobile {
            width: 27px;
          }
        }
      }
    }
    .arrow {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      transition: 0.3s;
      animation: arrow 3s infinite ease-in-out;
      img {
        width: 30px;
        object-fit: cover;
      }
    }
    @keyframes arrow {
      0% {
        bottom: 20px;
      }
      25% {
        bottom: 30px;
      }
      50% {
        bottom: 20px;
        opacity: 0;
      }
      75% {
        bottom: 30px;
        opacity: 0.5;
      }
      100% {
        bottom: 20px;
        opacity: 1;
      }
    }
  }
}

// Main
.main {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: url(../public/assets/image/main-bg.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  @include flexCenter;
  @include mobile() {
    background-position: -430px 0;
    display: none;
  }

  .main-inner {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 30px;

    position: absolute;
    top: 0;
    @include tablet {
      max-width: auto;
      width: 90%;
      gap: 20px;
    }

    h2 {
      font-size: 56px;
      color: #333;
      line-height: 72px;
      font-family: $font-NanumSquare;
      font-weight: 800;
      pointer-events: none;
      letter-spacing: 5px;

      @include tablet {
        font-size: 42px;
        line-height: 58px;
        letter-spacing: 3px;
      }
      @include mobile {
        font-size: 32px;
        line-height: 48px;
        text-align: center;
        text-shadow: 0 0 5px white;
        color: black;
        letter-spacing: 0px;
      }
    }
    .app-btn {
      display: flex;
      gap: 30px;
      @include tablet {
        gap: 20px;
      }
      @include mobile() {
        justify-content: center;
      }
      button {
        font-size: 23px;
        font-family: $font-Pretendard;
        font-weight: 700;
        color: #fff;
        display: flex;
        align-items: center;
        padding: 4px 13px;
        background-color: rgba(0, 0, 0, 0.6);
        border: none;
        outline: none;
        border-radius: 7px;
        cursor: pointer;
        @include tablet {
          font-size: 21px;
          padding: 6px 10px;
        }
        @include mobile {
          font-size: 16px;
          padding: 4px 10px;
          background-color: rgba(0, 0, 0, 0.9);
        }
        img {
          width: 40px;
          @include tablet {
            width: 30px;
          }
          @include mobile {
            width: 25px;
          }
        }
      }
    }
  }
}

// Banner-Title
.banner-title {
  width: 100%;
  padding:200px 0px;
  @include flexCenter();
  flex-direction: column;
  @include tablet {
    padding: 100px 0px 100px;
  }
  @include mobile {
    padding: 80px 0 80px;
  }
  h2 {
    font-size: 20px;
    line-height: 60px;
    font-family: $font-NanumSquare;
    font-weight: 800;
    color: #19984b;
    pointer-events: none;
    @include mobile {
      width: 100%;
      max-width: 90%;
      font-size: 19px;
      line-height: 40px;
      text-align: center;
    }
  }
  p {
    width: 100%;
    max-width: 1080px;
    text-align: center;
    font-size: 39px;
    line-height: 66px;
    font-family: $font-NanumSquare;
    font-weight: 700;
    margin: 16px auto 43px;
    pointer-events: none;
    @include tablet {
      max-width: auto;
      width: 80%;
      font-size: 22px;
      line-height: 42px;
      .mobile {
        display: none;
      }
    }
    @include mobile {
      width: 100%;
      max-width: 90%;
      font-size: 19px;
      line-height: 36px;
      margin: 13px auto 37px;
      text-align: left;
      font-weight: 800;
      color: rgb(51, 61, 75);
    }
  }
  .accordion {
    width: 100%;
    .card {
      width: 100%;
      @include flexCenter();
      border: none;
      background-color: transparent;
      .card-header {
        background: transparent;
        border: none;
        button {
          font-size: 20px;
          font-family: $font-NanumSquare;
          font-weight: 700;
          border: 2px solid #333;
          outline: none;
          padding: 18px 20px;
          border-radius: 10px;
          cursor: pointer;
          color: #333;
          background-color: #fff;
          @include tablet {
            font-size: 18px;
            padding: 14px 16px;
            border: 1px solid #333;
          }
          @include mobile {
            font-size: 16px;
            padding: 9px 12px;
          }
        }
        button.active {
          background-color: #333;
          color: #fff;
        }
      }
      .accordion-collapse {
        width: 100%;
        .card-body {
          width: 100%;
          padding: 0;
          h2 {
            width: 100%;
            text-align: center;
            font-size: 48px;
            line-height: 66px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            color: black;
            padding: 150px 0;
            margin-top: 100px;
            span {
              color: #19984b;
            }
            @include tablet() {
              font-size: 42px;
              line-height: 59px;
            }
            @include mobile() {
              font-size: 25px;
              line-height: 1.6;
              padding: 100px 0;
              margin: 100px auto 0;
            }
          }
        }
        .we-wrapper {
          width: 100%;
          max-width: 1140px;
          margin: 0 auto;
          display: flex;
          justify-content: space-between;
          padding-top: 100px;
          pointer-events: none;
          @include tablet {
            max-width: auto;
            width: 90%;
            flex-direction: column;
          }
          @include mobile {
            padding-top: 50px;
          }
          .title {
            p {
              font-size: 42px;
              text-align: start;
              font-family: $font-NanumSquare;
              font-weight: 800;
              line-height: 56px;
              margin: 0;
              span {
                color: #19984b;
              }
              @include tablet {
                font-size: 32px;
                line-height: 46px;
              }
              @include mobile {
                font-size: 25px;
                line-height: 39px;
              }
            }
          }
          .we-list {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 50px;
            @include tablet() {
              margin-top: 50px;
            }
            ul {
              list-style: disc;
              @include mobile() {
                list-style: none;
              }
              li {
                font-size: 18px;
                font-family: $font-NanumSquare;
                font-weight: 600;
                padding: 5px 0;
                .mobile {
                  display: none;
                }
                @include mobile {
                  font-size: 16px;
                  .mobile {
                    display: inline-block;
                  }
                }
                &:nth-child(1) {
                  font-size: 35px;
                  font-weight: 800;
                  list-style: none;
                  padding-bottom: 20px;
                  @include tablet {
                    font-size: 30px;
                  }
                  @include mobile {
                    font-size: 25px;
                    padding-bottom: 10px;
                  }
                }
              }
            }
          }
        }
        // Partners
        .partners {
          h2 {
            background-color: #f9fafb;
          }
          .partner-list {
            width: 100%;
            max-width: 1140px;
            margin: 0 auto;
            @include tablet() {
              width: 90%;
              max-width: auto;
            }
            ul {
              width: 100%;
              display: grid;
              grid-template-columns: repeat(5, 1fr);
              row-gap: 80px;
              column-gap: 50px;
              @include tablet() {
                grid-template-columns: repeat(4, 1fr);
              }
              @include mobile() {
                grid-template-columns: repeat(2, 1fr);
              }
              li {
                @include flexCenter();
                img {
                  width: 70%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
    }
  }
}
// Banner Title
.banner-title.bg {
  background-color: #f9fafb;
}


//History

.historyHero{
  img{
    width:100%;
    @include mobile {
      width:100%;

    }
  }
  
}

.we-wrapper {
  width: 100%;
  max-width: 1140px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  padding-top: 100px;
  padding-bottom: 100px;
  pointer-events: none;
  @include tablet {
    max-width: auto;
    width: 90%;
    flex-direction: column;
  }
  @include mobile {
    padding-top: 50px;
    padding-bottom: 50px;
  }
  .title {
    p {
      font-size: 42px;
      text-align: start;
      font-family: $font-NanumSquare;
      font-weight: 800;
      line-height: 56px;
      margin: 0;
      span {
        color: #19984b;
      }
      @include tablet {
        font-size: 32px;
        line-height: 46px;
      }
      @include mobile {
        font-size: 25px;
        line-height: 39px;
      }
    }
  }
  .we-list {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    gap: 50px;
    @include tablet() {
      margin-top: 50px;
    }
    ul {
      list-style: disc;
      @include mobile() {
        list-style: none;
      }
      li {
        font-size: 18px;
        font-family: $font-NanumSquare;
        font-weight: 600;
        padding: 5px 0;
        .mobile {
          display: none;
        }
        @include mobile {
          font-size: 16px;
          .mobile {
            display: inline-block;
          }
        }
        &:nth-child(1) {
          font-size: 35px;
          font-weight: 800;
          list-style: none;
          padding-bottom: 20px;
          @include tablet {
            font-size: 30px;
          }
          @include mobile {
            font-size: 25px;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}




// Story
.story {
  width: 100%;
  padding: 220px 40px 249px;
  overflow: hidden;
  @include tablet {
    padding: 100px 0px 149px;
  }
  @include mobile() {
    padding: 50px 0 109px;
  }

  .story-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    @include tablet {
      max-width: auto;
      width: 90%;
      flex-direction: column;
    }
    .story-content {
      p {
        margin: 0;
        padding: 0;
        img {
          width: 20px;
          object-fit: cover;
          @include mobile {
            width: 17px;
          }
        }
        @include mobile() {
          .mobile {
            display: none;
          }
        }
      }
      h2 {
        font-size: 42px;
        line-height: 56px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        pointer-events: none;

        span {
          color: #19984b;
        }
        @include tablet {
          font-size: 32px;
          line-height: 46px;
        }
        @include mobile {
          font-size: 25px;
          line-height: 35px;
        }
      }
      p {
        font-size: 22px;
        line-height: 34px;
        font-family: $font-NanumSquare;
        font-weight: 600;
        margin-top: 20px;
        pointer-events: none;
        color: rgb(107, 118, 132);
        @include tablet {
          font-size: 21px;
          line-height: 31px;
        }
        @include mobile {
          font-size: 16px;
          line-height: 23px;
          margin-top: 10px;
        }
      }
      .story-list {
        margin-top: 40px;
        display: flex;
        flex-direction: column;
        gap: 15px;
        @include tablet {
          margin-top: 40px;
          flex-direction: row;
          flex-wrap: wrap;
        }
        span {
          font-size: 24px;
          font-family: $font-Pretendard;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.5);
          position: relative;
          cursor: pointer;
          @include tablet {
            font-size: 18px;
            border: 1px solid #d3d3d3;
            padding: 6px 10px;
            border-radius: 7px;
            background-color: white;
          }
          @include mobile {
            font-size: 16px;
            padding: 4px 10px;
          }
        }
        span.active {
          color: #333;
          &::before {
            content: "";
            width: 200%;
            height: 5px;
            background-color: #19984b;
            position: absolute;
            right: 103%;
            top: 50%;
            transform: translateY(-50%);
            border-radius: 5px;
            @include tablet {
              display: none;
            }
          }
          @include tablet {
            background-color: #333;
            color: #fff;
          }
          @include mobile {
            font-weight: 500;
          }
        }
      }
    }

    .story-slide {
      border-radius: 15px;
      overflow: hidden;
      box-shadow: 0 0 17px 0px rgba(0, 0, 0, 0.3);
      background-color: #f9fafb;

      @include tablet {
        margin: 100px auto 0;
        width: 600px;
        height: 500px;
      }
      @include mobile {
        margin: 50px auto 0;
        border-radius: 10px;
        width: 100%;
        height: auto;
      }
      ul {
        width: 550px;
        list-style: none;
        height: 450px;
        display: flex;
        flex-direction: row nowrap;

        @include tablet {
          width: 100%;
          height: 100%;
        }

        li {
          width: 100%;
          .story-img {
            video {
              width: 100%;
              height: 350px;
              object-fit: cover;
              source {
                width: 100%;
              }
              @include tablet {
                height: 400px;
              }
              @include mobile {
                height: 60%;
              }
            }
          }
          .story-content {
            width: 100%;
            height: 100px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            gap: 5px;
            padding: 0 20px;
            position: relative;
            pointer-events: none;

            strong {
              font-size: 25px;
              font-weight: $font-NanumSquare;
              font-weight: 800;
              @include mobile {
                font-size: 18px;
              }
            }
            span {
              font-size: 17px;
              font-weight: $font-NanumSquare;
              font-weight: 600;
              @include mobile {
                font-size: 16px;
              }
            }
          }
        }
      }
    }
  }
}

// Recycle
.recycle {
  width: 100%;
  padding: 0 40px 249px;
  @include tablet {
    padding: 0 0 149px;
  }
  @include mobile {
    padding: 0 0 109px;
  }
  .recycle-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }

    .recycle-left,
    .recycle-right {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 250px;
      @include tablet {
        margin-top: 150px;
        flex-direction: column;
        align-items: flex-start;
      }
      @include mobile {
        margin-top: 80px;
      }
      .left-content,
      .right-content {
        @include tablet {
          width: 100%;
        }
        p.top-title {
          display: flex;
          align-items: center;
          gap: 10px;
          margin-bottom: 10px;
          img {
            width: 20px;
            object-fit: cover;
            @include mobile {
              width: 17px;
            }
          }
          font-size: 18px;
          font-family: $font-NanumSquare;
          font-weight: 800;
          color: #19984b;
          @include mobile {
            font-size: 16px;
          }
        }
        h2 {
          font-size: 38px;
          line-height: 56px;
          font-family: $font-NanumSquare;
          font-weight: 800;
          pointer-events: none;
          span {
            color: #19984b;
          }
          @include tablet {
            font-size: 32px;
            line-height: 46px;
          }
          @include mobile {
            font-size: 25px;
            line-height: 35px;
            .mobile {
              display: none;
            }
          }
        }
        p {
          font-size: 20px;
          line-height: 34px;
          font-family: $font-NanumSquare;
          font-weight: 600;
          margin-top: 20px;
          pointer-events: none;
          color: rgb(107, 118, 132);
          @include tablet {
            font-size: 18px;
            line-height: 26px;
          }
          @include mobile {
            font-size: 16px;
            line-height: 23px;
            .mobile {
              display: none;
            }
          }
        }
      }
      .left-img,
      .right-img {
        width: 450px;
        height: 350px;
        overflow: hidden;
        @include flexCenter();
        border-radius: 10px;
        box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.2);
        @include tablet {
          width: 600px;
          height: 500px;
          margin: 50px auto 0;
        }
        @include mobile {
          width: 100%;
          height: auto;
          aspect-ratio: 1/1;
          margin: 30px auto 0;
        }

        img {
          height: 100%;
          object-fit: cover;
        }
      }
    }
    .recycle-right {
      @include tablet {
        flex-direction: column-reverse;
      }
    }

    .recycle-icon {
      width: 100%;
      margin-top: 80px;
      @include tablet {
        margin-top: 50px;
      }
      @include mobile {
        margin-top: 30px;
      }
      ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        list-style: none;
        @include tablet {
          justify-content: center;
          gap: 50px;
          // flex-wrap: wrap;
        }
        @include mobile {
          gap: 20px;
        }
        li {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          @include tablet {
            width: 100px;
            height: 100px;
          }
          @include mobile {
            width: 80px;
            height: 80px;
          }
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        li.arrow {
          width: 70px;
          height: 70px;
          @include tablet() {
            width: 50px;
            height: 50px;
          }
          @include mobile() {
            width: 30px;
            height: 30px;
          }
        }
      }
    }
  }
}

// Second-Banner
.second-banner {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: url(../public/assets/image/hero-bg.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  @include flexCenter;
  @include mobile() {
    display: none;
  }

  .main-inner {
    width: 100%;
    height: 100%;
    max-width: 1140px;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    gap: 0px;
    position: absolute;
    top: 0;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    @include mobile() {
      flex-direction: column;
      justify-content: center;
      gap: 30px;
    }
    .content {
      h2 {
        font-size: 56px;
        color: #333;
        line-height: 72px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        pointer-events: none;
        @include tablet {
          font-size: 42px;
          line-height: 58px;
        }
        @include mobile {
          font-size: 32px;
          line-height: 48px;
          text-align: center;
          text-shadow: 0 0 10px white;
        }
      }
      p {
        font-size: 22px;
        line-height: 1.6;
        font-family: $font-NanumSquare;
        color: #191f28;
        font-weight: 700;
        margin-top: 20px;
        pointer-events: none;
        letter-spacing: 1px;
        @include mobile {
          font-size: 18px;
          line-height: 2;
          text-align: center;
          text-shadow: 0 0 5px white;
          font-weight: 800;
          letter-spacing: 0px;

          .mobile {
            display: none;
          }
        }
      }
    }
    .img {
      width: 500px;
      img {
        width: 100%;
        object-fit: cover;
      }
      @include tablet {
        width: 300px;
      }
      @include mobile {
        width: 100px;
      }
    }
  }
  .arrow {
    position: absolute;
    bottom: 50px;
    transition: 0.3s;
    animation: arrowpc 3s infinite ease-in-out;
    @include mobile {
      bottom: 30px;
    }

    img {
      width: 30px;
      object-fit: cover;
      @include mobile {
        width: 20px;
      }
    }
  }
  @keyframes arrowpc {
    0% {
      bottom: 50px;
    }
    25% {
      bottom: 60px;
    }
    50% {
      bottom: 50px;
      opacity: 0;
    }
    75% {
      bottom: 60px;
      opacity: 0.5;
    }
    100% {
      bottom: 50px;
      opacity: 1;
    }
  }
}
// Mobile-Second-Banner
.mobile-second-banner {
  display: none;
  @include mobile() {
    display: block;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: url(../public/assets/image/mobile-banner/m-banner02.jpg) center
      top no-repeat;
    background-size: cover;
    position: relative;
  }
  .content {
    @include flexCenter();
    flex-direction: column;
    padding-top: 110px;
    h2 {
      font-size: 28px;
      line-height: 1.3;
      text-align: center;
      color: black;
      letter-spacing: 0px;
      font-family: $font-NanumSquare;
      font-weight: 900;
      pointer-events: none;
    }
    p {
      font-size: 16px;
      font-family: $font-NanumSquare;
      font-weight: 700;
      line-height: 1.7;
      text-align: center;
      font-weight: 800;
      letter-spacing: 0px;
    }
  }
  .arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    animation: arrow 3s infinite ease-in-out;
    img {
      width: 30px;
      object-fit: cover;
    }
  }
  @keyframes arrow {
    0% {
      bottom: 20px;
    }
    25% {
      bottom: 30px;
    }
    50% {
      bottom: 20px;
      opacity: 0;
    }
    75% {
      bottom: 30px;
      opacity: 0.5;
    }
    100% {
      bottom: 20px;
      opacity: 1;
    }
  }
}

// PlasticHero
.hero {
  width: 100%;
  padding: 0 40px 249px;
  @include tablet {
    padding: 0 0 149px;
  }
  @include mobile {
    padding: 0 0 109px;
  }
  .hero-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    .hero-title {
      padding-top: 150px;
      pointer-events: none;
      @include mobile {
        padding-top: 80px;
      }
      h4 {
        font-size: 30px;
        font-family: $font-Pretendard;
        font-weight: 700;
        color: #19984b;
        @include mobile {
          font-size: 25px;
        }
      }
      p {
        font-size: 30px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        line-height: 42px;
        margin-top: 15px;
        span {
          color: #19984b;
        }
        @include mobile {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }
    .content {
      .content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        @include mobile {
          flex-wrap: wrap;
          justify-content: center;
          gap: 30px;
        }

        .content-small {
          width: 400px;
          height: 487px;
          display: flex;
          flex-direction: column;
          justify-content: space-around;
          align-items: center;
          background-color: #19984b;
          border-radius: 15px;

          @include tablet {
            width: 45%;
            height: auto;
            aspect-ratio: 1/1;
          }
          @include mobile {
            width: 100%;
            justify-content: center;
            gap: 20px;
          }
          span {
            font-size: 25px;
            font-family: $font-NanumSquare;
            font-weight: 500;
            text-align: center;
            color: #fff;
            line-height: 43px;
            pointer-events: none;
            @include tablet {
              font-size: 18px;
              line-height: 37px;
              padding: 0 10px;
            }
            @include mobile {
              font-size: 16px;
              line-height: 1.6;
              .mobile {
                display: none;
              }
            }
          }
          img {
            width: 40%;
            object-fit: cover;
            @include tablet {
              width: 30%;
            }
            @include mobile {
              width: 25%;
            }
          }
        }
        .content-big {
          width: 680px;
          height: 487px;
          border-radius: 15px;
          overflow: hidden;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
          @include tablet {
            width: 45%;
            height: auto;
            aspect-ratio: 1/1;
          }
          @include mobile {
            width: 100%;
          }
        }

        .content-image {
          width: 100%;
          height: 720px;
          @include flexCenter();
          border-radius: 15px;
          overflow: hidden;
          margin-bottom: 30px;
          @include tablet {
            width: 100%;
            height: 500px;
            margin-bottom: 0;
          }
          @include mobile() {
            height: 400px;
          }
          .mySwiper {
            width: 100%;
            height: 100%;
            border-radius: 15px;
            overflow: hidden;
            .swiper-button-prev,
            .swiper-button-next {
              color: white;
              @include mobile() {
                transform: scale(0.7);
              }
            }
            .swiper-pagination {
              display: none;
            }
            .swiper-wrapper {
              .swiper-slide {
                img {
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                }
              }
            }
          }
        }
      }
      .ai-robot {
        width: 100%;
        h5 {
          width: 100%;
          font-size: 42px;
          font-family: $font-Pretendard;
          font-weight: bold;
          text-align: center;
          padding: 30px 0;
          pointer-events: none;
          @include tablet {
            font-size: 32px;
          }
          @include mobile {
            font-size: 25px;
          }
        }
        p {
          width: 100%;
          text-align: center;
          font-size: 27px;
          font-family: $font-NanumSquare;
          font-weight: 500;
          color: #333;
          line-height: 50px;
          pointer-events: none;

          @include tablet {
            font-size: 20px;
            line-height: 30px;
            .mobile {
              display: none;
            }
          }
          @include mobile {
            font-size: 18px;
            line-height: 32px;
          }
        }
        .btn {
          width: 100%;
          @include flexCenter();
          gap: 50px;
          padding: 50px 0;
          @include tablet {
            padding: 20px 0;
          }
          @include mobile {
            gap: 50px;
          }
          button {
            font-size: 18px;
            font-family: $font-NanumSquare;
            font-weight: 700;
            border: 2px solid #333;
            background-color: transparent;
            padding: 10px 20px;
            border-radius: 10px;
            color: #333;
            cursor: pointer;
            @include tablet {
              font-size: 16px;
              padding: 6px 15px;
            }
            @include mobile {
              border: 1px solid #333;
              font-size: 16px;
            }
          }
          button.active {
            background-color: #333;
            color: #fff;
          }
        }
        .specification {
          width: 100%;
          padding: 50px 150px;
          background-color: #f9fafb;
          @include tablet {
            padding: 50px 20px;
          }
          @include mobile {
            margin-top: 30px;
          }
          strong {
            width: 100%;
            font-size: 32px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            @include tablet {
              font-size: 27px;
            }
            @include mobile {
              font-size: 25px;
            }
          }
          ul {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            margin-top: 30px;
            @include mobile {
              justify-content: space-between;
            }
            li {
              width: 50%;
              margin-bottom: 40px;
              @include mobile {
                width: 48%;
              }
              p {
                font-size: 22px;
                font-family: $font-NanumSquare;
                font-weight: 800;
                text-align: start;
                margin: 0;
                padding: 0;
                @include tablet {
                  font-size: 20px;
                }
                @include mobile {
                  font-size: 18px;
                }
              }
              span {
                font-size: 18px;
                font-family: $font-NanumSquare;
                font-weight: 600;
                @include tablet {
                  font-size: 16px;
                }
                @include mobile {
                  font-size: 13px;
                }
              }
            }
          }
        }
      }
      .first-footer {
        width: 100%;
        background-color: #f9fafb;
        border-radius: 15px;
        margin-top: 50px;
        @include flexCenter();
        flex-direction: column;
        padding: 50px;
        @include tablet {
          padding: 10px;
          img {
            width: 100%;
            object-fit: cover;
          }
        }
      }
    }
    .hero-five {
      display: flex;
      margin-top: 150px;
      justify-content: space-between;
      @include tablet {
        flex-direction: column;
      }
      @include mobile {
        margin-top: 80px;
      }

      .hero-as {
        width: 700px;
        background-color: #f9fafb;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
        gap: 80px;
        padding: 50px 30px;
        pointer-events: none;
        @include tablet {
          width: 100%;
          margin-top: 50px;
        }
        @include mobile {
          gap: 30px;
        }
        .as-inner {
          width: 100%;
          display: flex;
          justify-content: flex-start;
          align-items: center;

          .as-image {
            width: 120px;
            margin-right: 50px;
            @include tablet {
              width: 80px;
            }
            @include mobile {
              width: 70px;
              min-width: 70px;
              margin-right: 30px;
            }

            img {
              width: 100%;
              object-fit: cover;
            }
          }
          .as-content {
            strong {
              display: block;
              font-size: 22px;
              font-family: $font-NanumSquare;
              font-weight: 900;
              color: #19984b;
              margin-bottom: 20px;
              @include tablet {
                font-size: 20px;
              }
              @include mobile {
                font-size: 18px;
                margin-bottom: 10px;
              }
            }
            ul {
              width: 100%;
              list-style: none;
              li {
                font-size: 16px;
                font-family: $font-NanumSquare;
                font-weight: 700;
                margin-bottom: 5px;

                span {
                  font-weight: 800;
                }
                @include mobile {
                  font-size: 15px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// ThirdBanner
.third-banner {
  width: 100%;
  height: 750px;
  position: relative;
  overflow: hidden;
  @include tablet {
    height: 500px;
  }
  @include mobile() {
    display: none;
  }
  .bg {
    width: 100%;
    height: 100%;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include mobile() {
        width: auto;
        height: 100%;
      }
    }
    .main-wrapper {
      width: 100%;
      height: 100%;
      background: rgb(0, 0, 0);
      background: radial-gradient(
        circle,
        rgba(0, 0, 0, 0) 0%,
        rgba(0, 0, 0, 0.2049194677871149) 100%
      );
      position: absolute;
      top: 0;
      left: 0;
      @include tablet {
        background: radial-gradient(
          circle,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.8049194677871149) 100%
        );
      }
      @include mobile() {
        background: radial-gradient(
          circle,
          rgba(0, 0, 0, 0) 0%,
          rgba(0, 0, 0, 0.9049194677871149) 100%
        );
      }
    }
  }

  .banner-content {
    position: absolute;
    z-index: 10;
    top: 50%;
    left: 75%;
    transform: translate(-50%, -50%);
    pointer-events: none;

    @include mobile() {
      left: 50%;
    }
    strong {
      font-size: 42px;
      font-family: $font-NanumSquare;
      font-weight: 800;
      color: black;
      display: block;
      line-height: 55px;
      @include tablet {
        font-size: 32px;
        line-height: 46px;
      }
      @include mobile {
        font-size: 25px;
        line-height: 35px;
        text-shadow: 0 0 10px white;
        text-align: center;
      }
    }
    span {
      display: block;
      font-size: 27px;
      font-family: $font-NanumSquare;
      font-weight: 600;
      margin-top: 20px;
      line-height: 38px;
      color: rgb(51, 61, 75);

      @include tablet {
        font-size: 22px;
        line-height: 34px;
        .mobile {
          display: none;
        }
      }
      @include mobile {
        font-size: 18px;
        line-height: 28px;
        font-weight: 800;
        color: #191f28;
        text-shadow: 0 0 10px white;
        text-align: center;
      }
    }
  }
  .arrow {
    position: absolute;
    bottom: 50px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    animation: arrowpc 3s infinite ease-in-out;
    @include mobile {
      bottom: 30px;
    }
    img {
      width: 30px;
      object-fit: cover;
      @include mobile {
        width: 20px;
      }
    }
  }
  @keyframes arrowpc {
    0% {
      bottom: 50px;
    }
    25% {
      bottom: 60px;
    }
    50% {
      bottom: 50px;
      opacity: 0;
    }
    75% {
      bottom: 60px;
      opacity: 0.5;
    }
    100% {
      bottom: 50px;
      opacity: 1;
    }
  }
}
// Mobile-Third-Banner
.mobile-third-banner {
  display: none;
  @include mobile() {
    display: block;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: url(../public/assets/image/mobile-banner/m-banner03.jpg) center
      top no-repeat;
    background-size: cover;
    position: relative;
  }
  .content {
    @include flexCenter();
    flex-direction: column;
    padding-top: 110px;
    h2 {
      font-size: 28px;
      line-height: 1.3;
      text-align: center;
      color: black;
      letter-spacing: 0px;
      font-family: $font-NanumSquare;
      font-weight: 900;
      pointer-events: none;
    }
    p {
      font-size: 16px;
      font-family: $font-NanumSquare;
      font-weight: 700;
      line-height: 1.7;
      text-align: center;
      letter-spacing: 0px;
    }
  }
  .arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    animation: arrow 3s infinite ease-in-out;
    img {
      width: 30px;
      object-fit: cover;
    }
  }
  @keyframes arrow {
    0% {
      bottom: 20px;
    }
    25% {
      bottom: 30px;
    }
    50% {
      bottom: 20px;
      opacity: 0;
    }
    75% {
      bottom: 30px;
      opacity: 0.5;
    }
    100% {
      bottom: 20px;
      opacity: 1;
    }
  }
}

// Application
.app {
  width: 100%;
  padding: 0 40px 249px;
  pointer-events: none;
  @include tablet {
    padding: 0 0 149px;
  }
  @include mobile {
    padding: 0 0 109px;
  }

  .app-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    .app-first {
      width: 100%;
      padding: 100px 0 240px;
      @include mobile {
        padding: 80px 0 240px;
      }

      h4 {
        font-size: 30px;
        font-family: $font-Pretendard;
        font-weight: 700;
        color: #19984b;
        @include mobile {
          font-size: 25px;
          line-height: 35px;
          margin: 0;
        }
      }
      img {
        width: 100px;
        margin-top: 15px;
        @include mobile {
          width: 80px;
          margin-top: 5px;
        }
      }
      p {
        font-size: 30px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        line-height: 42px;
        margin-top: 15px;
        span {
          color: #19984b;
        }
        @include mobile {
          font-size: 24px;
          line-height: 35px;
        }
      }
      .first-image {
        width: 100%;
        height: 850px;
        position: relative;
        display: flex;
        justify-content: space-between;
        position: relative;

        @include tablet {
          height: auto;
          margin-top: 50px;
        }

        div {
          width: 390px;
          height: 670px;
          position: absolute;
          border-radius: 10px;
          @include tablet {
            position: relative;
            height: auto;
          }
        }
        .one {
          bottom: 60px;
          @include tablet() {
            bottom: 0;
          }
          img {
            width: 100%;
            object-fit: cover;
          }
        }
        .two {
          right: 0;
          top: -100px;

          img {
            width: 100%;
            object-fit: cover;
          }
          @include tablet {
            top: 0;
          }
        }
        .first-content {
          position: absolute;
          right: 0;
          bottom: 0;
          background: none;
          height: auto;
          width: auto;

          @include tablet {
            right: auto;
            left: 50%;
            bottom: -150px;
            transform: translateX(-50%);
            width: 100%;
          }

          p {
            font-size: 20px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            line-height: 35px;
            text-align: left;

            @include tablet() {
              text-align: center;
            }
            @include mobile {
              font-size: 16px;
              line-height: 30px;
              color: rgb(51, 61, 75);
            }
          }
        }
      }
    }
    .app-second {
      position: absolute;
      left: 0;
      width: 100%;
      padding: 100px 0;
      background-color: #f9fafb;
      @include mobile {
        padding: 80px 0;
      }
      .second-title {
        max-width: 1140px;
        margin: 0 auto;
        @include tablet {
          max-width: auto;
          width: 90%;
        }
        h4 {
          font-size: 30px;
          font-family: $font-Pretendard;
          font-weight: 700;
          color: #19984b;
          @include mobile {
            font-size: 25px;
            line-height: 35px;
            margin: 0;
          }
        }
        p {
          font-size: 30px;
          font-family: $font-NanumSquare;
          font-weight: 800;
          line-height: 42px;
          margin-top: 15px;
          span {
            color: #19984b;
          }
          @include mobile {
            font-size: 24px;
            line-height: 35px;
          }
        }
      }
      //
      .second-list {
        width: 100%;
        max-width: 1140px;
        margin: 0 auto;
        overflow: hidden;
        margin-top: 100px;
        @include tablet {
          max-width: auto;
          width: 90%;
          margin-top: 50px;
        }
        ul {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          list-style: none;
          @include mobile {
            flex-flow: wrap;
          }
          li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            @include mobile {
              width: 50%;
              margin-bottom: 30px;
            }

            .img {
              width: 130px;
              height: 150px;
              @include flexCenter();
              img {
                width: 100%;
                object-fit: cover;
              }
              @include tablet {
                width: 80px;
                height: 100px;
              }
              @include mobile {
                width: 70px;
                height: 90px;
              }
            }
            .content {
              pointer-events: none;
              p {
                font-size: 22px;
                font-family: $font-NanumSquare;
                font-weight: 800;
                color: #19984b;
                margin-top: 50px;
                margin-bottom: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                span {
                  display: inline-block;
                  margin: 0;
                  margin-right: 3px;
                  font-size: 22px;
                  display: inline-block;
                  color: #19984b;
                  font-weight: 800;

                  @include tablet {
                    font-size: 17px;
                  }
                }
                @include tablet {
                  margin-top: 20px;
                  font-size: 18px;
                }
              }
              span {
                display: block;
                font-size: 18px;
                font-family: $font-NanumSquare;
                font-weight: 700;
                margin-top: 10px;
                text-align: center;
                color: rgb(107, 118, 132);
                @include tablet {
                  font-size: 16px;
                  margin-top: 5px;
                }
              }
            }
          }
        }
      }
    }

    .app-third {
      width: 100%;
      padding: 100px 0;
      margin-top: 704px;
      position: relative;

      @include tablet {
        margin-top: 604px;
      }
      @include mobile {
        margin-top: 754px;
      }
      .third-title {
        h4 {
          font-size: 30px;
          font-family: $font-Pretendard;
          font-weight: 700;
          color: #19984b;
          @include mobile {
            font-size: 25px;
            line-height: 35px;
            margin: 0;
          }
        }
        img {
          width: 100px;
          margin-top: 15px;
          @include mobile {
            width: 80px;
            margin-top: 5px;
          }
        }
        p {
          font-size: 30px;
          font-family: $font-NanumSquare;
          font-weight: 800;
          line-height: 42px;
          margin-top: 15px;
          span {
            color: #19984b;
          }
          @include mobile {
            font-size: 24px;
            line-height: 35px;
          }
        }
        .m-txt {
          display: none;
          @include tablet {
            display: block;
            font-size: 18px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            line-height: 25px;
            color: rgb(107, 118, 132);
          }
          @include mobile {
            font-weight: 600;
            font-size: 16px;
            line-height: 23px;
            .mobile {
              display: none;
            }
          }
        }
      }
      .third-content {
        width: 100%;
        display: flex;
        justify-content: space-around;
        margin-top: 100px;
        @include tablet {
          justify-content: center;
          align-items: center;
        }
        .txt {
          font-size: 20px;
          font-family: $font-NanumSquare;
          font-weight: 800;
          line-height: 32px;
          color: rgb(51, 61, 75);

          p {
            margin-top: 150px;
          }
          @include tablet {
            display: none;
          }
        }

        .phone-img {
          width: 390px;
          height: 670px;
          img {
            width: 100%;
            object-fit: cover;
          }
          @include mobile {
            width: 290px;
            height: 570px;
          }
        }
      }
      .third-list {
        width: 100%;
        position: absolute;
        bottom: 150px;
        @include mobile {
          bottom: 200px;
        }
        ul {
          width: 100%;
          display: flex;
          justify-content: space-between;
          align-items: center;
          li {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            border-radius: 8px;
            @include tablet {
              width: 150px;
              height: 150px;
            }
            @include mobile {
              width: 85px;
              height: 85px;
            }
            img {
              width: 100%;
              height: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
  }
}

.esg-banner {
  background: url(../public/assets/image/esg-banner01.jpg) center center
    no-repeat;
  background-size: cover;
  @include mobile() {
    display: none;
  }
  .main-wrapper {
    display: none;
  }
  .main-inner {
    .content {
      h2,
      p {
        color: #fff;
        text-shadow: 0 0 10px black;
      }
    }
  }
}
// Mobile-Four-Banner
.mobile-four-banner {
  display: none;
  @include mobile() {
    display: block;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: url(../public/assets/image/mobile-banner/m-banner04.jpg) center
      top no-repeat;
    background-size: cover;
    position: relative;
  }
  .content {
    @include flexCenter();
    flex-direction: column;
    padding-top: 110px;
    h2 {
      font-size: 28px;
      line-height: 1.3;
      text-align: center;
      color: white;
      letter-spacing: 0px;
      font-family: $font-NanumSquare;
      font-weight: 900;
      pointer-events: none;
    }
    p {
      font-size: 16px;
      font-family: $font-NanumSquare;
      line-height: 1.7;
      text-align: center;
      font-weight: 500;
      letter-spacing: 0px;
      color: white;
    }
  }
  .arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    animation: arrow 3s infinite ease-in-out;
    img {
      width: 30px;
      object-fit: cover;
    }
  }
  @keyframes arrow {
    0% {
      bottom: 20px;
    }
    25% {
      bottom: 30px;
    }
    50% {
      bottom: 20px;
      opacity: 0;
    }
    75% {
      bottom: 30px;
      opacity: 0.5;
    }
    100% {
      bottom: 20px;
      opacity: 1;
    }
  }
}

// ESG
.esg {
  width: 100%;
  padding: 0 40px 249px;
  pointer-events: none;
  @include tablet {
    padding: 0 0 149px;
  }
  @include mobile() {
    padding: 0 0 109px;
  }
  .esg-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      width: 90%;
      max-width: auto;
    }
    .hero-title {
      padding-top: 150px;
      @include mobile() {
        padding-top: 80px;
      }
      h4 {
        font-size: 30px;
        font-family: $font-Pretendard;
        font-weight: 700;
        color: #19984b;
        @include mobile() {
          font-size: 25px;
        }
      }
      p {
        font-size: 30px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        line-height: 42px;
        margin-top: 15px;
        span {
          color: #19984b;
        }
        @include mobile() {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }
    .content {
      .content-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 50px;
        @include mobile() {
          flex-direction: column;
          gap: 50px;
        }
        .content-small {
          width: 400px;
          height: 487px;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          background-color: #19984b;
          border-radius: 15px;
          overflow: hidden;
          background: url(../public/assets/image/esg-ai.png) center center
            no-repeat;
          background-size: cover;
          @include tablet {
            width: 45%;
            height: auto;
            aspect-ratio: 1/1;
            border-radius: 10px;
            overflow: hidden;
          }
          @include mobile() {
            width: 300px;
            height: 300px;
          }
          span {
            font-size: 23px;
            font-family: $font-NanumSquare;
            font-weight: 700;
            text-align: center;
            color: #fff;
            line-height: 43px;
            pointer-events: none;
            padding: 30px;
            @include tablet {
              padding: 15px;
              font-size: 18px;
              line-height: 28px;
            }
          }
        }
        .content-big {
          width: 680px;
          height: 487px;
          border-radius: 15px;
          overflow: hidden;
          position: relative;
          background-color: black;
          @include tablet {
            width: 45%;
            height: auto;
            aspect-ratio: 1/1;
            border-radius: 10px;
          }
          @include mobile() {
            width: 300px;
            height: 300px;
          }
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            position: absolute;
            top: 0;
            left: 0;
            opacity: 0.5;
          }
          p {
            width: 100%;
            position: absolute;
            text-align: center;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 23px;
            font-family: $font-NanumSquare;
            font-weight: 700;
            color: #fff;
            span {
              font-size: 26px;
              font-weight: 900;
            }
            @include tablet() {
              font-size: 20px;
              span {
                font-size: 22px;
              }
            }
          }
        }

        .content-image {
          width: 100%;
          height: auto;
          overflow: hidden;
          @include flexCenter();
          border-radius: 15px;
          img {
            width: 150%;
            object-fit: cover;
          }
        }
      }
      .first-footer {
        width: 100%;
        height: 500px;
        background-color: #f9fafb;
        border-radius: 15px;
        margin-top: 50px;
        overflow: hidden;
        @include tablet {
          height: auto;
        }
        .esg-nav {
          width: 100%;
          background-color: #19984b;
          p {
            font-size: 22px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            color: #fff;
            text-align: center;
            padding: 10px 0;
            @include tablet {
              font-size: 20px;
            }
            @include mobile() {
              font-size: 18px;
            }
          }
        }
        ul {
          width: 100%;
          display: flex;
          justify-content: space-around;
          align-items: center;
          padding: 50px 0;
          @include tablet {
            padding: 20px 0;
          }
          @include mobile() {
            padding: 30px 0;
          }

          li {
            width: 33.3%;
            height: 300px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 20px;
            padding: 0 50px;
            &:nth-child(2) {
              border-left: 2px solid #d3d3d3;
              border-right: 2px solid #d3d3d3;
            }
            @include mobile() {
              height: auto;
              justify-content: flex-start;
              padding: 0;
            }

            .img {
              width: 50px;
              height: 100px;
              @include flexCenter();

              @include tablet {
                width: auto;
                height: 70px;
              }
              @include mobile() {
                height: 50px;
              }

              img {
                height: 100%;
                object-fit: cover;
              }
            }
            span {
              display: block;
              font-size: 22px;
              font-family: $font-NanumSquare;
              font-weight: 800;
              @include tablet {
                font-size: 18px;
                text-align: center;
                height: 60px;
                .mobile {
                  display: none;
                }
              }
              @include mobile() {
                font-size: 16px;
                padding: 0 5px;
                height: 50px;
                margin-top: 10px;
              }
            }
            .arrow {
              width: 30px;
              object-fit: cover;
              @include tablet {
                width: 25px;
              }
              @include mobile() {
                width: 20px;
              }
            }
            p {
              font-size: 25px;
              font-family: $font-NanumSquare;
              font-weight: 800;
              color: #19984b;
              @include tablet {
                font-size: 20px;
              }
              @include mobile() {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
    // 02
    .esg-second {
      .hero-title {
        span.ect {
          display: block;
          font-size: 16px;
          display: block;
          margin-top: 10px;
          @include mobile() {
            font-size: 14px;
            margin-top: 8px;
          }
        }
      }
      .ai-robot {
        width: 100%;
        margin-top: 50px;
        @include tablet {
          margin-top: 80px;
        }
        @include mobile() {
          margin-top: 80px;
        }
        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 70px;
          @include tablet {
            gap: 50px;
          }
          li {
            width: 100%;
            img {
              width: 100%;
              object-fit: cover;
            }
          }
        }
      }
    }
    // 03
    .esg-third {
      width: 100%;
      .content {
        width: 100%;
        margin-top: 100px;
        @include tablet {
          margin-top: 50px;
        }
        ul {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 30px;
          @include tablet() {
            gap: 40px;
          }
          li {
            width: 100%;
            display: flex;
            justify-content: space-between;
            align-items: center;
            @include tablet() {
              flex-direction: column;
              align-items: flex-start;
            }
            .title {
              height: 270px;
              @include tablet() {
                height: auto;
              }
              p {
                writing-mode: vertical-lr;
                font-size: 25px;
                font-family: $font-NanumSquare;
                font-weight: 800;
                background-color: white;
                box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.1);
                border-radius: 10px;
                height: 100%;
                padding: 0px 35px;
                text-align: center;
                box-sizing: border-box;
                @include tablet() {
                  writing-mode: horizontal-tb;
                  font-size: 20px;
                  padding: 5px 20px;
                }
                @include mobile() {
                  font-size: 16px;
                  padding: 3px 14px;
                }
              }
            }
            .image {
              height: 270px;
              border-radius: 15px;
              overflow: hidden;
              img {
                width: 100%;
                object-fit: cover;
              }
              @include tablet() {
                width: 100%;
                height: auto;
                img {
                  width: 100%;
                }
              }
            }
          }
        }
      }
    }
    .esg-four {
      width: 100%;
      .hero-title {
        span.ect {
          font-size: 16px;
          display: block;
          margin-top: 10px;
          @include mobile() {
            font-size: 15px;
            margin-top: 8px;
          }
        }
      }

      .content {
        width: 100%;
        display: flex;
        gap: 30px;
        margin-top: 100px;
        @include tablet {
          flex-direction: column;
          align-items: center;
        }
        @include mobile() {
          margin-top: 50px;
          gap: 40px;
        }
        .img {
          height: 480px;
          @include tablet {
            height: 380px;
          }
          @include mobile() {
            height: 300px;
          }
          img {
            height: 100%;
            object-fit: cover;
          }
        }
        .inner {
          width: 100%;
          height: 480px;
          @include tablet {
            height: auto;
            margin-top: 50px;
          }
          ul {
            width: 100%;
            height: 100%;
            list-style: none;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 30px;
            @include tablet {
              gap: 40px;
            }
            li {
              display: flex;
              align-items: center;
              gap: 30px;

              @include mobile() {
                gap: 20px;
              }

              .arrow {
                width: 100px;
                height: 100px;
                @include flexCenter();
                img {
                  width: 30px;
                  height: 40px;
                  object-fit: cover;
                  transform: rotate(-90deg);
                }
                @include tablet {
                  width: 50px;
                  height: 50px;
                  img {
                    width: 20px;
                    height: 30px;
                    object-fit: cover;
                  }
                }
                @include mobile() {
                  display: none;
                  width: 40px;
                  height: 40px;
                  img {
                    width: 70%;
                    height: auto;
                    object-fit: cover;
                  }
                }
              }
              .main-text {
                width: 100%;
                display: flex;
                align-items: center;
                background-color: #f9fafb;
                box-shadow: 0 0 14px 0px rgba(0, 0, 0, 0.2);
                border-radius: 15px;
                overflow: hidden;
                padding: 10px 20px;
                @include tablet {
                  border-radius: 10px;
                }
                @include mobile() {
                  padding: 0;
                  justify-content: space-between;
                }
                .text-icon {
                  width: 110px;
                  height: 110px;
                  @include flexCenter();
                  @include mobile() {
                    width: 80px;
                    height: 80px;
                  }
                  img {
                    width: 100%;
                    text-align: center;
                    object-fit: cover;
                  }
                }
                .text-content {
                  display: flex;
                  flex-direction: column;
                  padding-left: 25px;

                  @include mobile() {
                    width: 80%;
                    padding: 10px 15px;
                  }
                  strong {
                    font-size: 25px;
                    font-family: $font-NanumSquare;
                    font-weight: 900;
                    color: #19984b;
                    margin-bottom: 8px;
                    @include mobile() {
                      font-size: 19px;
                      margin-bottom: 0;
                      padding: 7px 0;
                    }
                  }
                  span {
                    font-size: 20px;
                    font-family: $font-NanumSquare;
                    font-weight: 700;
                    color: rgb(51, 61, 75);
                    @include mobile() {
                      font-size: 16px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .esg-five {
      width: 100%;
      .hero-title {
        span.ect {
          font-size: 16px;
          display: block;
          margin-top: 10px;
          @include mobile() {
            font-size: 15px;
            margin-top: 8px;
          }
        }
      }
      .content {
        width: 100%;
        margin-top: 100px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 50px;
        @include mobile() {
          margin-top: 50px;
        }
        .donation {
          width: 100%;
          display: block;
          text-align: center;
          box-shadow: 0 0 14px 0px rgba(0, 0, 0, 0.2);
          background-color: #f9fafb;
          border-radius: 15px;
          @include tablet {
            border-radius: 10px;
          }
          p {
            font-size: 22px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            padding: 10px 0;
            margin: 0;
            color: rgb(51, 61, 75);
            @include tablet {
              font-size: 20px;
            }
            @include mobile() {
              font-size: 18px;
            }
          }
          span {
            display: block;
            font-size: 28px;
            font-family: $font-NanumSquare;
            font-weight: 900;
            color: #19984b;
            padding-bottom: 20px;
            @include tablet {
              font-size: 25px;
            }
            @include mobile() {
              font-size: 21px;
            }
          }
        }
        .arrow {
          width: 40px;
          @include tablet {
            width: 30px;
          }
          @include mobile() {
            width: 25px;
          }
        }
        .donation-list {
          width: 100%;
          ul {
            width: 100%;
            display: flex;
            justify-content: space-between;
            list-style: none;
            @include tablet {
              flex-flow: wrap;
            }
            li {
              width: 230px;
              height: 500px;
              border-radius: 15px;
              overflow: hidden;
              box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.2);
              background-color: #f9fafb;

              @include tablet {
                width: 45%;
                height: auto;
                aspect-ratio: 1/1;
                margin-bottom: 50px;
                overflow: hidden;
              }
              .donation-img {
                width: 100%;
                height: 85%;
                overflow: hidden;
                @include flexCenter();
                @include tablet {
                  height: 80%;
                }
                @include mobile() {
                  height: 70%;
                }
                img {
                  height: 100%;
                  object-fit: cover;
                }
              }
              span {
                font-size: 22px;
                font-family: $font-NanumSquare;
                font-weight: 800;
                color: #333;
                @include flexCenter();
                height: 15%;
                @include tablet {
                  height: 20%;
                  font-size: 20px;
                }
                @include mobile() {
                  height: 30%;
                  font-size: 18px;
                  font-weight: 900;
                }
              }
            }
          }
        }
      }
    }
  }
}

// NEWS
.news,
.newslist {
  width: 100%;
  padding: 200px 40px 249px;
  background-color: #f9fafb;
  @include tablet {
    padding: 100px 0px 149px;
  }
  @include mobile {
    padding: 80px 0 109px;
  }
  .news-inner,
  .newslist-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    .news-title {
      p {
        font-size: 18px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        color: #19984b;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 42px;
        line-height: 56px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        pointer-events: none;
        @include tablet {
          font-size: 32px;
          line-height: 46px;
        }
        @include mobile() {
          font-size: 25px;
          line-height: 35px;
        }
        span {
          color: #19984b;
        }
      }
    }
    .news-list {
      width: 100%;
      margin-top: 30px;
      display: flex;
      gap: 50px;
      @include tablet {
        gap: 20px;
      }
      @include mobile() {
        margin-top: 20px;
      }
      button {
        font-size: 18px;
        font-family: $font-NanumSquare;
        font-weight: 700;
        background-color: #d3d3d3;
        color: #333;
        border: none;
        outline: none;
        padding: 6px 20px;
        border-radius: 15px;
        cursor: pointer;
        @include tablet {
          font-size: 16px;
          padding: 3px 15px;
          border-radius: 10px;
        }
        @include mobile() {
          font-size: 14px;
          border-radius: 5px;
        }
      }
      button.active {
        background-color: #19984b;
        color: #fff;
      }
    }
    .news-content {
      width: 100%;
      margin-top: 50px;
      ul {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        flex-flow: wrap;
        @include mobile() {
          justify-content: center;
        }
        li {
          width: 330px;
          height: 500px;
          display: flex;
          flex-direction: column;
          align-items: center;
          border-radius: 15px;
          overflow: hidden;
          background-color: white;
          box-shadow: 0 0 17px 0px rgba(0, 0, 0, 0.3);
          cursor: pointer;
          @include tablet {
            width: 280px;
            height: 400px;
            margin-bottom: 50px;
          }
          @include mobile() {
            width: 100%;
            max-width: 380px;
          }
          img {
            height: 60%;
            object-fit: cover;
          }
          .news-text {
            width: 100%;
            height: 40%;
            padding: 20px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            h5 {
              font-size: 23px;
              line-height: 36px;
              font-family: $font-NanumSquare;
              font-weight: 800;
              @include tablet {
                font-size: 20px;
                line-height: 29px;
              }
            }
            span {
              font-size: 16px;
              font-family: $font-NanumSquare;
              font-weight: 400;
              @include tablet {
                font-size: 15px;
              }
            }
          }
        }
      }
    }
    .more-btn {
      margin-top: 50px;
      @include tablet {
        @include flexCenter;
      }
      @include mobile() {
        margin-top: 30px;
      }
      button {
        font-size: 20px;
        font-family: $font-NanumSquare;
        font-weight: 700;
        padding: 10px 20px;
        background-color: white;
        color: #333;
        border: none;
        outline: 1px solid black;
        border-radius: 10px;
        cursor: pointer;
        @include tablet {
          font-size: 18px;
          padding: 4px 15px;
        }
        @include mobile() {
          font-size: 16px;
        }
      }
    }
  }
}

// FAQ
.faq {
  width: 100%;
  padding: 200px 40px 249px;
  background-color: #f9fafb;
  @include tablet {
    padding: 100px 0 149px;
  }
  .faq-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    .faq-title {
      p {
        font-size: 18px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        color: #19984b;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 42px;
        line-height: 56px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        pointer-events: none;
        @include tablet {
          font-size: 32px;
          line-height: 46px;
        }
        @include mobile() {
          font-size: 25px;
          line-height: 35px;
        }
        span {
          color: #19984b;
        }
      }
    }
    .accordion {
      margin-top: 100px;
      border-top: 2px solid black;
      border-bottom: 2px solid black;
      @include mobile() {
        margin-top: 50px;
        border-top: 1px solid black;
        border-bottom: 1px solid black;
      }
      .accordion-item {
        border: none;
        border-bottom: 2px solid #d3d3d3;
        @include mobile() {
          border-bottom: 1px solid #d3d3d3;
        }
        .accordion-header {
          --bs-accordion-active-bg: transparent;
          --bs-accordion-btn-focus-border-color: transparent;
          button {
            box-shadow: none;
            font-size: 22px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            color: black;
            padding: 30px 20px;
            @include tablet {
              font-size: 19px;
              padding: 22px 20px;
            }
            @include mobile() {
              font-size: 16px;
              padding: 15px 16px;
            }
          }
        }
        .accordion-collapse {
          .accordion-body {
            font-size: 18px;
            line-height: 2;
            font-family: $font-NanumSquare;
            font-weight: 600;
            color: #333;
            padding: 30px 20px;
            @include tablet {
              font-size: 16px;
            }
            @include mobile() {
              font-size: 15px;
            }
          }
        }
        &:last-child {
          border: none;
        }
      }
    }
    .more-btn {
      margin-top: 50px;
      @include tablet {
        margin-top: 100px;
        @include flexCenter();
      }
      @include mobile() {
        margin-top: 50px;
      }
      button {
        font-size: 20px;
        font-family: $font-NanumSquare;
        font-weight: 700;
        padding: 10px 20px;
        background-color: white;
        color: #333;
        border: none;
        outline: 1px solid black;
        border-radius: 10px;
        cursor: pointer;
        @include tablet {
          font-size: 18px;
          padding: 4px 15px;
        }
        @include mobile() {
          font-size: 16px;
        }
      }
    }
  }
}

// With
.with {
  width: 100%;
  padding: 0px 40px 249px;
  background-color: #f9fafb;
  @include tablet {
    padding: 0px 0 149px;
  }
  @include mobile() {
    padding: 0px 0 109px;
  }
  .with-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    .with-title {
      p {
        font-size: 18px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        color: #19984b;
        margin-bottom: 10px;
      }
      h2 {
        font-size: 42px;
        line-height: 56px;
        font-family: $font-NanumSquare;
        font-weight: 800;
        pointer-events: none;
        span {
          color: #19984b;
        }
        @include tablet {
          font-size: 32px;
          line-height: 46px;
        }
        @include mobile() {
          font-size: 25px;
          line-height: 35px;
        }
      }
    }
    .content {
      width: 100%;
      margin-top: 100px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      @include mobile() {
        margin-top: 50px;
        flex-wrap: wrap;
        justify-content: center;
        gap: 30px;
      }
      .use,
      .install {
        width: 500px;
        height: 360px;
        border-radius: 15px;
        box-shadow: 0 0 13px 0px rgba(0, 0, 0, 0.2);
        @include tablet {
          width: 45%;
          height: auto;
          border-radius: 10px;
          aspect-ratio: 1/1;
        }
        @include mobile() {
          width: 300px;
          height: 300px;
        }
      }
      .use {
        background: url(../public/assets/image/with/10-1.jpg) center center
          no-repeat;
        background-size: cover;
        background-color: white;
        position: relative;

        p {
          font-size: 22px;
          font-family: $font-NanumSquare;
          font-weight: 800;
          margin-top: 30px;
          padding: 0 20px;
          @include tablet {
            font-size: 18px;
          }
        }
        span {
          margin: 0 0 0 20px;
          font-size: 16px;
          font-family: $font-NanumSquare;
          font-weight: 500;
          border: 1px solid #d3d3d3;
          padding: 6px 20px;
          border-radius: 15px;
          cursor: pointer;
          @include tablet {
            padding: 4px 15px;
          }
        }
      }
      .install {
        background: url(../public/assets/image/with/10-2.jpg) center center
          no-repeat;
        background-position: 40px 0;
        background-size: cover;
        background-color: white;
        position: relative;
        @include tablet() {
          background-position: 40px 50px;
        }
        p {
          font-size: 22px;
          font-family: $font-NanumSquare;
          font-weight: 800;
          margin-top: 30px;
          padding: 0 20px;
          @include tablet {
            font-size: 18px;
          }
        }
        span {
          margin: 0 0 0 20px;
          font-size: 16px;
          font-family: $font-NanumSquare;
          font-weight: 500;
          border: 1px solid #d3d3d3;
          padding: 6px 20px;
          border-radius: 15px;
          background-color: white;
          cursor: pointer;
          @include tablet {
            padding: 4px 15px;
          }
        }
      }
    }
  }
}

// With-Banner
.with-banner {
  width: 100%;
  height: calc(var(--vh, 1vh) * 100);
  background: url(../public/assets/image/with-bg.jpg) center center no-repeat;
  background-size: cover;
  position: relative;
  @include tablet() {
    background-position: -1400px 0;
  }
  @include mobile() {
    background-position: -1100px 0;
    display: none;
  }
  .bg-cover {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .banner-inner {
      width: 100%;
      height: 100%;
      max-width: 1140px;
      margin: 0 auto;
      display: flex;
      align-items: center;
      @include tablet {
        max-width: auto;
        width: 90%;
      }

      .banner-content {
        pointer-events: none;
        width: 100%;
        strong {
          font-size: 42px;
          font-family: $font-Pretendard;
          font-weight: 800;
          color: white;
          display: block;
          line-height: 58px;
          @include mobile() {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 32px;
            line-height: 48px;
            text-shadow: 0 0 10px black;
          }
        }
        span {
          display: block;
          font-size: 27px;
          color: #fff;
          font-family: $font-NanumSquare;
          font-weight: 400;
          margin-top: 20px;
          line-height: 38px;
          @include tablet {
            font-size: 23px;
          }
          @include mobile() {
            font-size: 18px;
            line-height: 28px;
            text-align: center;
            text-shadow: 0 0 10px black;
          }
        }
      }
    }
  }
}

// Mobie-Five-Banner
.mobile-five-banner {
  display: none;
  @include mobile() {
    display: block;
    width: 100%;
    height: calc(var(--vh, 1vh) * 100);
    background: url(../public/assets/image/mobile-banner/m-banner05.jpg) center
      top no-repeat;
    background-size: cover;
    position: relative;
  }
  .content {
    @include flexCenter();
    flex-direction: column;
    padding-top: 110px;
    h2 {
      font-size: 28px;
      line-height: 1.3;
      text-align: center;
      color: white;
      letter-spacing: 0px;
      font-family: $font-NanumSquare;
      font-weight: 900;
      pointer-events: none;
    }
    p {
      font-size: 16px;
      font-family: $font-NanumSquare;
      line-height: 1.7;
      text-align: center;
      font-weight: 600;
      letter-spacing: 0px;
      color: white;
    }
  }
  .arrow {
    position: absolute;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
    transition: 0.3s;
    animation: arrow 3s infinite ease-in-out;
    img {
      width: 30px;
      object-fit: cover;
    }
  }
  @keyframes arrow {
    0% {
      bottom: 20px;
    }
    25% {
      bottom: 30px;
    }
    50% {
      bottom: 20px;
      opacity: 0;
    }
    75% {
      bottom: 30px;
      opacity: 0.5;
    }
    100% {
      bottom: 20px;
      opacity: 1;
    }
  }
}

// Footer
.footer {
  width: 100%;
  padding: 100px 40px 109px;
  background-color: #f9fafb;
  @include tablet {
    padding: 100px 0 109px;
  }
  @include mobile() {
    padding: 80px 0 89px;
  }
  .footer-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    .info {
      width: 100%;
      border-bottom: 1px solid black;
      pointer-events: none;
      ul {
        width: 100%;
        display: flex;
        gap: 10px;
        flex-flow: wrap;
        li {
          p {
            font-size: 16px;
            font-family: $font-NanumSquare;
            padding: 0 10px;
            line-height: 14px;
            margin: 0;
            margin-bottom: 10px;
            @include tablet {
              font-size: 14px;
              margin: 0;
            }
          }
          &:first-child {
            p {
              padding-left: 0;
            }
          }
          @include tablet {
            &:last-child {
              p {
                padding: 0;
              }
            }
          }
        }
        .fax {
          p {
            border-left: 2px solid #666666;
            border-right: 2px solid #666666;
            @include tablet {
              margin: 0;
            }
          }
        }
      }
      p {
        font-size: 16px;
        font-family: $font-NanumSquare;
        @include tablet {
          margin: 0;
          margin-top: 20px;
          margin-bottom: 10px;
          @include tablet {
            font-size: 14px;
          }
        }
      }
    }
    .adress {
      width: 100%;
      padding-top: 1rem;
      .company {
        width: 100%;
        display: flex;
        align-items: center;
        gap: 20px;
        p {
          font-size: 16px;
          font-family: $font-NanumSquare;
          margin: 0;
          padding: 0;
          pointer-events: none;
          @include tablet {
            font-size: 14px;
          }
        }
      }
      p {
        font-size: 16px;
        font-family: $font-NanumSquare;
        margin: 0;
        padding: 10px 0;
        pointer-events: none;
        @include tablet {
          font-size: 14px;
        }
      }
    }
    .use-info {
      width: 100%;
      p {
        font-size: 16px;
        font-family: $font-NanumSquare;
        margin: 0;
        span {
          cursor: pointer;
        }
        @include tablet {
          font-size: 14px;
        }
      }
    }
    .copy {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 50px;
      span {
        font-size: 16px;
        font-family: $font-NanumSquare;
        pointer-events: none;
        @include tablet {
          font-size: 14px;
        }
      }
    }
  }
}

// ASK
.ask {
  width: 100%;
  padding: 100px 0px 109px;
  @include mobile() {
    padding: 80px 0 109px;
  }
  .ask-inner {
    width: 100%;
    max-width: 1140px;
    margin: 0 auto;
    @include tablet {
      max-width: auto;
      width: 90%;
    }
    strong {
      display: block;
      width: 100%;
      font-size: 42px;
      font-family: $font-NanumSquare;
      font-weight: 800;
      padding: 100px 0;

      span {
        color: #19984b;
      }
      @include tablet {
        font-size: 32px;
        line-height: 46px;
      }
      @include mobile() {
        font-size: 25px;
        line-height: 35px;
        padding: 50px 0;
      }
    }
    .search-input {
      width: 100%;
      position: relative;
      img {
        width: 30px;
        height: 30px;
        object-fit: cover;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        stroke: #666666;
        @include tablet {
          width: 20px;
          height: 20px;
        }
      }
      input {
        width: 100%;
        outline: none;
        border: none;
        padding: 15px 60px;
        font-size: 24px;
        color: #333;
        font-family: $font-NanumSquare;
        font-weight: 400;
        border-bottom: 1px solid #d3d3d3;
        &::placeholder {
          font-size: 24px;
          color: lightgray;
          font-family: $font-NanumSquare;
          font-weight: 400;
          @include tablet {
            font-size: 21px;
          }
        }
        @include tablet {
          padding: 10px 40px;
          font-size: 21px;
        }
      }
    }
    .accordion {
      margin-top: 100px;
      border: none;
      @include mobile() {
        margin-top: 50px;
      }
      .accordion-item {
        border: none;
        border-bottom: 2px solid #d3d3d3;
        .accordion-header {
          --bs-accordion-active-bg: transparent;
          --bs-accordion-btn-focus-border-color: transparent;
          button {
            box-shadow: none;
            font-size: 22px;
            font-family: $font-NanumSquare;
            font-weight: 800;
            color: black;
            padding: 30px 20px;
            @include tablet {
              font-size: 19px;
              padding: 22px 20px;
            }
            @include mobile() {
              font-size: 16px;
              padding: 15px 16px;
            }
          }
        }
        .accordion-collapse {
          .accordion-body {
            font-size: 18px;
            font-family: $font-NanumSquare;
            font-weight: 600;
            color: #333;
            padding: 30px 20px;
            @include tablet {
              font-size: 16px;
            }
            @include mobile() {
              font-size: 15px;
            }
          }
        }
      }
    }
    .more-btn {
      width: 100%;
      margin: 80px 0;
      @include flexCenter();
      button {
        font-size: 20px;
        font-family: $font-NanumSquare;
        font-weight: 500;
        padding: 10px 20px;
        background-color: white;
        color: #333;
        border: none;
        outline: 1px solid black;
        border-radius: 10px;
        cursor: pointer;
        @include tablet {
          font-size: 18px;
          padding: 4px 15px;
        }
        @include mobile() {
          font-size: 16px;
        }
      }
    }
  }
  .email {
    width: 100%;
    background-color: #f9fafb;
    .email-inner {
      width: 100%;
      max-width: 1140px;
      margin: 0 auto;
      padding: 40px 0;
      position: relative;
      overflow-x: hidden;
      @include tablet {
        max-width: auto;
        width: 90%;
      }
      .email-image {
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 100%;
        @include tablet() {
          height: 70%;
        }
        @include mobile() {
          height: 50%;
          right: -50px;
        }
        img {
          height: 100%;
          object-fit: cover;
        }
      }

      .open-time {
        pointer-events: none;
        p {
          font-size: 22px;
          font-family: $font-NanumSquare;
          font-weight: 700;
          margin: 0;
          @include tablet {
            font-size: 20px;
          }
          @include mobile() {
            font-size: 18px;
          }
        }
        span {
          display: block;
          font-size: 22px;
          font-family: $font-NanumSquare;
          font-weight: 700;
          color: #666666;
          margin-top: 5px;
          @include mobile() {
            font-size: 20px;
          }
        }
      }
      .email-adress {
        margin-top: 50px;
        pointer-events: none;

        span {
          display: block;
          font-size: 22px;
          font-family: $font-NanumSquare;
          font-weight: 700;
          color: #666666;
          @include mobile() {
            font-size: 20px;
          }
        }
        p {
          font-size: 22px;
          font-family: $font-NanumSquare;
          font-weight: 700;
          margin: 0;
          @include tablet {
            font-size: 20px;
          }
          @include mobile() {
            font-size: 18px;
          }
        }
      }
    }
  }
}

// NewsList
.newslist {
  background-color: transparent;
  @include tablet {
    margin-top: 100px;
  }
  @include mobile() {
    margin-top: 30px;
  }
  .newslist-inner {
    .more-btn {
      @include flexCenter();
      margin-top: 100px;
      @include mobile() {
        margin-top: 30px;
      }
    }
    .news-content {
      @include tablet {
        margin-top: 100px;
      }
      @include mobile() {
        margin-top: 50px;
      }
      ul {
        li {
          margin-bottom: 50px;
        }
      }
    }
  }
}


//partners compoenent

.mob-list{
  display:none;
  margin-top:2rem;
  img{
    width: 100%;
    object-fit: contain;
  @include mobile {
    width:90%;
  }
  }
  @include mobile {
    display:grid;
    grid-template-columns: auto auto;
    row-gap:2rem;
    place-items: center;
  }
}




.partners{
  max-width:1140px;
  margin-inline: auto;
  padding:0 60px;
  @include mobile {
    padding:0px 15px;
  }
  .partners-main{
    .list{
      // margin-top: clamp(2.5rem, 1.814rem + 2.9268vw, 4.375rem);
      margin-top: clamp(3.125rem, 1.5244rem + 6.8293vw, 7.5rem);

      img{
        // width:140px;
        // height:60px;
        // object-fit: contain;
        @include mobile {
          object-fit: contain;
        }
      }
      .first-list{
        display:grid;
        grid-template-columns: 40% 60%;
        // @include tablet{
        // grid-template-columns: 30% 70%;
        // }
        @include tablet {
          display: block;
        }
        .list-l{
          .title{
            p {
              font-size: 42px;
              text-align: start;
              font-family: $font-NanumSquare;
              font-weight: 800;
              line-height: 56px;
              margin: 0;
              // span {
              //   color: #19984b;
              // }
              @include tablet {
                font-size: 32px;
                line-height: 46px;
              }
              @include mobile {
                font-size: 25px;
                line-height: 39px;
                text-align: center;
              }
            }
            p:nth-child(2){
              color: #19984b;
            }
          }
          .description {
            margin-top:1rem;
          p{
            font-family: $font-NanumSquare;
            font-size: clamp(1.125rem, 1.0335rem + 0.3902vw, 1.375rem);
            font-weight: 500;
            margin:0;
            @include mobile {
              text-align: center;
            }
          }
          }
        }
        .list-r{
          // display:flex;
          // flex-wrap: wrap;
          column-gap:3rem;
          row-gap:2rem;
          justify-content: space-between;
          align-items: center;
          display: grid;
          grid-template-columns: auto auto auto;
          @include tablet {
              margin-top:3.5rem;
          }
          @include mobile {
            // margin-top:2rem;
            // justify-content: initial;
            // grid-template-columns: auto auto;
            // column-gap: 0;
            // place-items: center;
            // row-gap: 1rem;
            display:none;

          }
        }
      }
      .second-list{
        display:grid;
        grid-template-columns: auto auto auto auto auto;
        justify-content: space-between;
        row-gap:3rem;
        flex-wrap: wrap;
        margin-top:2rem;
       
        @include tablet{
          grid-template-columns: auto auto auto;
        }
        @include mobile {
          grid-template-columns: auto auto;
          place-items: center;
          justify-content: initial;
          row-gap: 1.5rem;
          display:none;
          // justify-content: baseline;
          // justify-content: center;
        }
      }
      // .list-partners{
      //   display: flex;
      //   flex-wrap: wrap;
      //   column-gap: 30px;
      //   row-gap: 30px;
      //   img{
      //   max-width: 130px;
      //   width:100%; 
      //   }
      // }
     
    }
  }
}


// view button 

.btn-block{
  // display: flex;
  height:100%;
  width: 100%;
  text-align: center;
   margin-top: clamp(1.875rem, 0.8036rem + 4.5714vw, 4.375rem);
}

.view{
  font-family: "NanumSquare";
  font-weight: 800;
  background-color: #fff;
  color: #19984b;
  outline: none;
  border-radius: 10px;
  // box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.3);
  border: 1px solid #19984b;
  width: 150px;
  height:40px;
  font-size: 16px;
}

// Send
.send {
  position: fixed;
  right: 50px;
  bottom: 70px;
  font-size: 18px;
  font-family: $font-NanumSquare;
  font-weight: 800;
  background-color: #19984b;
  color: #fff;
  outline: none;
  border-radius: 10px;
  box-shadow: 0 0 12px 0px rgba(0, 0, 0, 0.3);
  border: none;
  outline: none;
  &:hover {
    background-color: #19984b;
  }
  @include tablet {
    font-size: 18px;
    right: 30px;
    bottom: 60px;
  }
  @include mobile() {
    font-size: 16px;
    padding: 4px 10px;
    right: 20px;
    bottom: 50px;
  }
}
.send-form {
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 99999;

  .modal-dialog {
    width: 100%;
    height: 100%;
    @include flexCenter();
    max-width: 700px !important;
    margin: 0 auto;

    @include mobile() {
      width: 100%;
    }

    .modal-content {
      @include tablet {
        width: 90%;
      }
      .modal-header {
        position: relative;
        padding: 40px 0;
        @include mobile() {
          padding: 30px 0;
        }
        .h4 {
          font-size: 25px;
          line-height: 36px;
          font-family: $font-NanumSquare;
          font-weight: 700;
          pointer-events: none;
          text-align: center;
          color: #19984b;
          margin: 0 auto;
          position: absolute;
          left: 50%;
          transform: translateX(-50%);
          @include mobile() {
            font-size: 21px;
            line-height: auto;
          }
        }
        button {
          position: absolute;
          right: 30px;
          @include mobile() {
            right: 20px;
          }
        }
      }
      .modal-body {
        padding: 50px;
        padding-top: 20px;
        @include mobile() {
          padding: 20px 10px;
        }
        form {
          width: 100%;
          display: flex;
          flex-direction: column;
          @include mobile() {
            gap: 20px;
          }
          div {
            width: 100%;
          }
          .form-subject {
            display: flex;
            flex-direction: column;
            margin-bottom: 30px;
            textarea {
              width: 100%;
              border: 1px solid rgba(34, 34, 34, 0.3);
              border-radius: 4px;
              outline: none;
              font-family: $font-NanumSquare;

              font-size: 17px;
              padding: 0 15px;
              height: 80px;
              resize: none;
              padding: 8px 15px;
            }
            textarea::-webkit-scrollbar {
              display: none;
            }
            textarea::placeholder {
              font-family: $font-NanumSquare;

              font-size: 14px;
              line-height: 18px;
              font-weight: 500;
            }
          }
          label {
            font-size: 20px;
            line-height: 60px;
            font-family: $font-NanumSquare;
            font-weight: 600;
            width: 100%;
            @include mobile() {
              font-size: 18px;
              line-height: 1;
              margin-bottom: 10px;
            }
          }
          input {
            width: 100%;
            height: 100%;
            font-family: $font-NanumSquare;

            font-size: 14px;
            line-height: 18px;
            font-weight: 500;
            outline: none;
            padding: 10px 18px;
            border-radius: 20px;
            border: 1px solid rgba(34, 34, 34, 0.3);
          }
          input[type="number"]::-webkit-outer-spin-button,
          input[type="number"]::-webkit-inner-spin-button {
            -webkit-appearance: none;
            -moz-appearance: none;
            appearance: none;
          }
          input[type="submit"] {
            display: block;
            width: 50%;
            border-radius: 10px;
            cursor: pointer;
            font-family: $font-NanumSquare;
            font-weight: 800;
            font-size: 18px;
            margin: 0 auto;
            background-color: #f9fafb;
            color: #333;
          }
        }
      }
    }
  }
}



.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: white;
  padding: 20px;
  border-radius: 20px;
  z-index: 9999;
}

.popup-inner {
  position: relative;
}

// .close-btn {
//   position: absolute;
//   top: 0;
//   left: 0;
//   cursor: pointer;
//   padding: 3px 5px;
//   background-color: rgba(162, 162, 162, 0.5);
//   border-radius: 5px;
//   color: white;
//   z-index: 99999;
// }

  .popup .close-btn {
  position: absolute;
  top: 0px;
  right: 0px;
  background-color: #19984b;
  color: white;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 12px;
  z-index: 999999;
}



.image-container {
  position: relative;
}

.popup-image {
  width: 600px;
  
}

@media (max-width: 991px) {
  .popup-image {
  width: 100%;
    
  }.image-container {
    width: 600px;
  }
}
@media (max-width: 600px) {
  .image-container {
    width: 350px;
  }
}
@media (max-width: 400px) {
  .image-container {
    width: 250px;
  }
}



//popup-start
// .popup {
//   position: fixed;
//   top: 50%;
//   left: 50%;
//   transform: translate(-50%, -50%);
//   background-color: white;
//   padding: 20px;
//   border-radius: 5px;
//   box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
// }

// .popup .popup-inner {
//   position: relative;
// }

// .popup .close-btn {
//   position: absolute;
//   top: -10px;
//   right: -10px;
//   background-color: #ff6347;
//   color: white;
//   width: 20px;
//   height: 20px;
//   border-radius: 50%;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   cursor: pointer;
//   font-size: 12px;
// }

// .popup .image-container {
//   margin-top: 20px;
//   text-align: center;
// }

// .popup .popup-image {
//   max-width: 70%;
// }
//popup-end